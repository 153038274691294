<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
var moment = require('moment');
moment.locale('fr');
import { Api } from '../../../helpers';
import VideosCardVue from "../../../components/widgets/VideosCard.vue";
/**
 * Projects-overview component
 */
export default {
  page: {
    title: "Projects Overview",
    meta: [{ name: "description", content: appConfig.description }]
  },
  components: { Layout, PageHeader, VideosCardVue },
  data() {
    return {
      moment: moment,
      categorie: [],
      videos: [],
      title: "Details Catégorie",
      items: [
        {
          text: "Catégorie",
          href: "/projects/categories"
        },
        {
          text: "Détails",
          active: true
        }
      ]
    };
  },
  async mounted() {
    this.id = this.$route.params.id
    const result = await Api.get('/streamvod/rest/categories/' + this.$route.params.id)
    this.categorie = result.data.content
    this.image = this.categorie.images.url


    const response = await Api.get('/streamvod/rest/videos/' + this.$route.params.id + '/videos')
    this.videos = response.data.content

  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="card" style="padding: 30px; border-radius: 7px;">
      <div class="row">
        <div class="col-xl-4">
          <div class="product-detai-imgs" style="">
            <img :src="image" alt style="
            object-fit: cover;
            height: 200px;
            width: 100%;
            border-radius: 10px;
            margin-bottom: 20px;
            " />
            <div style="
            top: 0px;
            height: 200px;
            width: 94%;
            position: absolute;
            border-radius: 10px;
            z-index: 111;
            -webkit-box-shadow : inset 0px -185px 112px -95px rgba(4,87,17,0.57);
            -moz-box-shadow : inset 0px -185px 112px -95px rgba(4,87,17,0.57);
            box-shadow : inset 0px -185px 112px -95px rgba (4,87,17,0.57);
            "></div>
          </div>
        </div>
        <div class="col-xl-1"></div>
        <div class="col-xl-7">
          <div class="mt-3">
            <div style="text-align: justify;">
              <h2>{{ categorie.name }}</h2>
              <br>
              <h5><strong>{{ $t("pages.overviewCateg.dataCateg.description") }}</strong> : {{ categorie.description }}
              </h5>
              <h5><strong>{{ $t("pages.overviewCateg.dataCateg.nbVideo") }}</strong> : {{ videos.length }}</h5>
            </div>
          </div>
        </div>
      </div>
    </div>

    <br><br>
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="table-responsive">
              <table class="table align-middle table-nowrap table-hover">
                <thead class="table-light">
                  <tr>
                    <th scope="col" style="width: 70px">Image</th>
                    <th scope="col">Titre</th>
                    <th scope="col">Durée</th>
                    <th scope="col">Likes</th>
                    <th scope="col">Commentaires</th>
                    <th scope="col">Vues</th>
                    <th scope="col">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  <VideosCardVue v-for="video in videos.slice().reverse()" :key="video.id" :affichage="'Liste'"
                    :image="video.image.url" :imagePaysage="video.imagepaysage.url" :title="video.title"
                    :vues="video.nbVues" :likes="video.likedUsers.length" :time="video.duration" :years="video.years"
                    :id="video.id" :commentaires="video.comments.length" :chaines="channels" :categ="categories"
                    :tag="tagList" :description="video.description" :channelId="video.channel"
                    :categorie="video.categorie" :initialTags="video.tags" :programme-id="video.programme"
                    :programme="programmes"></VideosCardVue>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- <div class="row">
      <div class="col-12">
        <div class="text-center my-3">
          <a href="javascript:void(0);" class="text-success">
            <i class="bx bx-loader bx-spin font-size-18 align-middle me-2"></i>
            Load more
          </a>
        </div>
      </div>
    </div> -->
  </Layout>
</template>